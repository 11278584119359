<template>
  <div class="failure">
    <GlobalInfoBar
      title="快捷备注"
      type="quickRemark"
      content="快捷备注将用于门店端小程序-首页报价时展示，提供快捷选择"
    >
      <template slot="right">
        <div class="quk_right">
          <p style="margin-right: 20px">备注必填商家</p>
          <p>
            已设置<span class="blu_text" @click="seeALLmerchant"
              >{{ redyMErchtNum }}个</span
            >商家必填<span @click="addMercant" class="red_text">添加</span>
          </p>
        </div>
      </template>
    </GlobalInfoBar>
    <GlobalChunk icon="search" title="快捷备注选项">
      <div class="case-title">
        <div style="text-align: left; margin-bottom: 20px">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="AddFailure"
            >添加</el-button
          >
        </div>
        <div class="case-title_min">
          说明:
          添加的快捷备注将显示在门店端小程序-首页报价界面（字数限制10字内）
        </div>
      </div>
      <el-table border :data="faultTypeList.records">
        <el-table-column type="index" width="100" align="center" label="序号">
        </el-table-column>
        <el-table-column align="center" label="故障名称" prop="name" />
        <el-table-column
          align="center"
          label="添加时间"
          prop="createTime"
          width="180"
        />
        <el-table-column
          v-slot="{ row }"
          align="center"
          :label="item.title"
          :key="item.key"
          width="100"
          :prop="item.key"
          v-for="item in switchData"
        >
          <el-switch
            active-text="开"
            inactive-text="关"
            inactive-color="#b6b9d0"
            @change="(v) => switchChange(v, row, item)"
            v-model="row[item.key]"
          >
          </el-switch>
        </el-table-column>
        <el-table-column align="center" label="排序" width="200">
          <template slot-scope="scope">
            <el-input-number
              size="small"
              v-model="scope.row.sort"
              :min="0"
              @change="handleChange(scope.row)"
            ></el-input-number>
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作" width="423">
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="deleteBtn(scope.row)"
              type="danger"
              >删除</el-button
            >
            <el-button
              @click.native.prevent="dblclick(scope.row)"
              type="primary"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="text-align: right; margin-right: 200px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        layout="total,  prev, pager, next, jumper"
        :total="faultTypeList.total"
        :background="true"
      >
      </el-pagination>
      <!-- <div class="faultBtn">
        <div class="faultBut_item" v-for="(item) in faultTypeList.records" :key="item.id" @dblclick="dblclick(item)">
          {{item.name}}
          <i class="el-icon-close" @click="deleteBtn(item)" />
        </div>
      </div> -->
    </GlobalChunk>
    <!--弹框-->
    <GlobalDialog
      :title="dialogTitle"
      :show="dialogVisible"
      :width="dialogWidth"
      @resetPopupData="resetPopupData"
      @submitPopupData="submitPopupData"
      @onClose="onClose"
    >
      <div
        class="AddDialog"
        v-if="
          this.removeFaultAdd === 'AddBtn' || this.removeFaultAdd === 'EditBtn'
        "
      >
        <div class="title">说明：添加的故障将显示在用户端报价页面</div>
        <div style="margin: 40px 0">
          <el-input
            v-model="AddTextarea"
            placeholder="请输入故障类型（10字内)"
            maxlength="10"
            clearable
          ></el-input>
        </div>
      </div>
      <div
        style="
          font-size: 16px;
          color: #666666;
          text-align: center;
          margin-bottom: 10px;
          text-align: left;
        "
        v-if="this.removeFaultAdd === 'deleteBtn'"
      >
        是否确认删除该快捷备注？
      </div>
    </GlobalDialog>
    <!-- 四种状态上架家操作  -->
    <DialogAction
      v-bind="switchDialogProps"
      @config="switchConfig"
      @close="
        switchDialogProps.show = false;
        handleCurrentChange(currentPage4);
      "
    />
    <!-- 添加门店商户 -->
    <el-dialog
      title="添加门店商户"
      :visible.sync="automatedDloding"
      :close-on-click-modal="false"
      width="1250px"
      @closed="closeStorediong"
    >
      <div style="display: flex; justify-content: space-between">
        <div style="width: 800px; margin-right: 20px">
          <el-form
            :inline="true"
            :model="createInline"
            class="demo-form-inline"
          >
            <el-form-item label="门店商家">
              <el-select
                size="small"
                v-model="createInline.companyId"
                clearable
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in compydataList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属渠道">
              <el-select
                size="small"
                v-model="createInline.matchChannelId"
                placeholder="请选择所属渠道"
                clearable
              >
                <el-option
                  v-for="item in channelselectlist"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                @click="serchvoluntarilySubmit('no')"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
          <div>
            <p style="margin-bottom: 20px">选择商户</p>
            <el-table
              ref="alltipleTable"
              :data="createStoreList"
              :row-key="(row) => row.companyId"
              tooltip-effect="dark"
              border
              max-height="530"
              style="width: 100%"
              @selection-change="zdmodelleSelect"
            >
              <el-table-column
                type="selection"
                :reserve-selection="true"
                align="center"
              >
              </el-table-column>
              <el-table-column
                type="index"
                align="center"
                label="序号"
                width="50"
              ></el-table-column>
              <el-table-column label="商户名称" align="center">
                <template slot-scope="{ row }">{{
                  row.companyName || "--"
                }}</template>
              </el-table-column>
              <el-table-column label="联系人" align="center">
                <template slot-scope="{ row }"
                  >{{ row.contact }}-{{ row.phone }}</template
                >
              </el-table-column>
              <el-table-column label="入驻时间" align="center">
                <template slot-scope="{ row }">{{
                  row.createTime || "--"
                }}</template>
              </el-table-column>
              <el-table-column label="所属渠道" align="center">
                <template slot-scope="{ row }">{{
                  row.matchChannelName || "--"
                }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div style="width: 400px">
          <p style="margin-bottom: 20px">已选择商户</p>
          <el-table
            ref="redtipleTable"
            :data="selectedStoreList"
            tooltip-effect="dark"
            border
            max-height="640"
            style="width: 100%"
          >
            <el-table-column
              type="index"
              align="center"
              label="序号"
              width="50"
            >
            </el-table-column>
            <el-table-column label="商户名称" align="center">
              <template slot-scope="{ row }">{{
                row.companyName || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="{ row }">
                <p @click="detaleCompy(row)" class="action_red">删除</p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click.native="
            automatedDloding = false;
            selectedStoreList = [];
          "
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="receiptTypeBtnLoading"
          @click.native="automatedSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 操作提示 -->
    <el-dialog
      title="操作提示"
      :visible.sync="quotationShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="command = ''"
    >
      <div class="enable-tip">
        <div>说明：启用后，该商户下单、重评下单时备注为必填项</div>
        <div class="tip-sure">
          是否确认启用<span style="color: #0981ff"
            >所选{{ selectedStoreList.length }}个商户</span
          >的备注必填功能？
        </div>
      </div>
      <div class="command">
        <div style="width: 100px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click.native="
            quotationShow = false;
            command = '';
          "
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="hidePriceButLoading"
          @click.native="quotationSubmit"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <!-- 已启用备注必填商户 -->
    <el-dialog
      title="已启用备注必填商户"
      :visible.sync="startmatedDloding"
      :close-on-click-modal="false"
      width="1200px"
      @closed="closeStorediong"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: self-start;
        "
      >
        <el-form :inline="true" :model="createInline" class="demo-form-inline">
          <el-form-item label="门店商家">
            <el-select
              size="small"
              v-model="createInline.companyId"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in compydataList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属渠道">
            <el-select
              size="small"
              v-model="createInline.matchChannelId"
              placeholder="请选择所属渠道"
              clearable
            >
              <el-option
                v-for="item in channelselectlist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              type="primary"
              @click="serchvoluntarilySubmit('rd')"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
        <el-button
          type="danger"
          size="small"
          :disabled="selectedStoreList.length === 0"
          @click="openSetting('batch')"
          >批量关闭</el-button
        >
      </div>
      <div>
        <el-table
          ref="addtipleTable"
          :data="createStoreList"
          tooltip-effect="dark"
          border
          max-height="530"
          style="width: 100%"
          @selection-change="startSelect"
        >
          <el-table-column type="selection" align="center"> </el-table-column>
          <el-table-column
            type="index"
            align="center"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column label="商户名称" align="center">
            <template slot-scope="{ row }">{{
              row.companyName || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="联系人" align="center">
            <template slot-scope="{ row }"
              >{{ row.contact }}-{{ row.phone }}</template
            >
          </el-table-column>
          <el-table-column label="入驻时间" align="center">
            <template slot-scope="{ row }">{{
              row.createTime || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="所属渠道" align="center">
            <template slot-scope="{ row }">{{
              row.matchChannelName || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="添加人" align="center">
            <template slot-scope="{ row }"
              >{{ row.userName }}-{{ row.userAccount }}</template
            >
          </el-table-column>
          <el-table-column label="启用时间" align="center">
            <template slot-scope="{ row }">{{
              row.enableTime || "--"
            }}</template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="{ row }">
              <p @click="openSetting('one', row)" class="action_red">关闭</p>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button
          @click.native="
            startmatedDloding = false;
            selectedStoreList = [];
          "
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="receiptTypeBtnLoading"
          @click.native="automatedSubmit"
          >确定</el-button
        > -->
      </span>
    </el-dialog>
    <!-- 操作提示 -->
    <el-dialog
      title="操作提示"
      :visible.sync="turnshow"
      :close-on-click-modal="false"
      width="500px"
      @closed="command = ''"
    >
      <div class="enable-tip">
        <div>关闭后，该商户下单、重评下单时，备注为选填项</div>
        <div class="tip-sure" v-if="turntype != 'one'">
          是否确认关闭<span style="color: #0981ff"
            >所选{{ selectedStoreList.length }}个商户</span
          >的备注必填功能？
        </div>
        <div class="tip-sure" v-else>
          是否确认关闭<span style="color: #0981ff">{{
            turndata.companyName
          }}</span
          >的备注必填功能？
        </div>
      </div>
      <div class="command">
        <div style="width: 100px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click.native="
            turnshow = false;
            command = '';
          "
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="hidePriceButLoading"
          @click.native="detaleredySubmit"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import DialogAction from "@/components/common/DialogAction.vue";

export default {
  name: "failure",
  components: { DialogAction },
  data() {
    return {
      turnshow: false,
      turntype: "",
      turndata: {},
      startmatedDloding: false,
      quotationShow: false,
      hidePriceButLoading: false,
      command: "",
      receiptTypeBtnLoading: false,
      channelselectlist: [],
      compydataList: [],
      createInline: {
        companyId: "",
        matchChannelId: "",
      },
      automatedDloding: false,
      createStoreList: [],
      redyMErchtNum: 0,
      selectedStoreList: [],
      switchDialogProps: {
        show: false,
        title: "上架提示",
        isTip: true,
        tipText: "",
        row: "",
        item: "",
        value: false,
      },
      currentPage4: 1,
      num: 0,
      dialogVisible: false, // 弹框的出现与否
      dialogTitle: "", // 标题
      dialogWidth: 380,
      faultTypeList: [],
      removeFaultAdd: "",
      AddTextarea: "",
      FaultId: "",
      // 开关数据源
      switchData: [
        {
          key: "canStartUp",
          title: "正常开机",
        },
        {
          key: "notStartUp",
          title: "不能开机",
        },
        {
          key: "fireNew",
          title: "全新机",
        },
        {
          key: "senile",
          title: "功能机/老年机",
        },
      ],
    };
  },
  created() {
    this.FaultBtnList();
    this.getlistStoreNotRequired(true);
  },
  methods: {
    openSetting(type, row) {
      this.turnshow = true;
      this.turntype = type;
      if (row) {
        this.turndata = row;
      }
    },
    closeStorediong() {
      this.automatedDloding = false;
      this.selectedStoreList = [];
      this.createInline = {
        companyId: "",
        matchChannelId: "",
      };
      this.$refs.alltipleTable.clearSelection();
    },
    //自动报价确定批量选择门店
    automatedSubmit() {
      if (this.selectedStoreList.length == 0) {
        return this.$message.error("请选择门店商家");
      }
      this.quotationShow = true;
    },
    //批量关闭备注
    detaleredySubmit() {
      if (!this.command) {
        return this.$message.error("请输入谷歌动态验证码");
      }
      let zdprams = {
        command: this.command,
        companyIds: [],
      };
      if (this.turntype == "one") {
        zdprams.companyIds.push(this.turndata.companyId);
      } else {
        zdprams.companyIds = this.selectedStoreList.map(
          (item) => item.companyId
        );
      }
      this.hidePriceButLoading = true;
      console.log(zdprams);
      _api
        .deleteStoreNotRequiredByBatch(zdprams)
        .then((res) => {
          if (res.code == 1) {
            this.$message({
              message: "操作成功！",
              type: "success",
            });
            this.startmatedDloding = false;
            this.selectedStoreList = [];
            this.command = "";
            this.getlistStoreNotRequired(true);
            this.turnshow = false;
            this.hidePriceButLoading = false;
          } else {
            this.hidePriceButLoading = false;
          }
        })
        .catch((err) => {
          this.hidePriceButLoading = false;
        });
    },
    //批量备注必填功能
    quotationSubmit() {
      if (!this.command) {
        return this.$message.error("请输入谷歌动态验证码");
      }
      let zdprams = {
        command: this.command,
        companyIds: [],
      };
      zdprams.companyIds = this.selectedStoreList.map((item) => item.companyId);
      this.hidePriceButLoading = true;
      console.log(zdprams);
      _api
        .addStoreNotRequired(zdprams)
        .then((res) => {
          if (res.code == 1) {
            this.$message({
              message: "操作成功！",
              type: "success",
            });
            this.quotationShow = false;
            this.selectedStoreList = [];
            this.command = "";
            this.getlistStoreNotRequired(true);
            this.automatedDloding = false;
            this.hidePriceButLoading = false;
          } else {
            this.hidePriceButLoading = false;
          }
        })
        .catch((err) => {
          this.hidePriceButLoading = false;
        });
    },
    seeALLmerchant() {
      this.getMerchantList();
      this.getMatchChannelSelect();
      this.getlistStoreNotRequired(true);
      this.startmatedDloding = true;
    },
    addMercant() {
      this.getMerchantList();
      this.getMatchChannelSelect();
      this.getlistStoreNotRequired(false);
      this.automatedDloding = true;
    },
    getlistStoreNotRequired(isRequired) {
      console.log(this.createInline);
      this.createStoreList=[]
      _api
        .getlistStoreNotRequired({
          isRequired,
          ...this.createInline,
        })
        .then((res) => {
          this.createStoreList = res.data;
          if (isRequired) {
            this.redyMErchtNum = res.data.length;
          }
        });
    },
    //渠道
    getMatchChannelSelect() {
      _api.getMatchChannelSelect().then((res) => {
        this.channelselectlist = res.data;
      });
    },
    // 门店商家下拉
    getMerchantList() {
      _api.getSelectList().then((res) => {
        if (res.code === 1) {
          this.compydataList = res.data.map((v) => {
            return {
              label: v.name,
              value: v.id,
            };
          });
        }
      });
    },
    //搜索
    serchvoluntarilySubmit(type) {
      if (type == "no") {
        this.getlistStoreNotRequired(false);
      } else {
        this.getlistStoreNotRequired(true);
      }
    },
    startSelect(val) {
      this.selectedStoreList = val;
    },
    zdmodelleSelect(val) {
      console.log(val);
      this.selectedStoreList = val;
    },
    //删除
    detaleCompy(row) {
      console.log(row);
      this.createStoreList.forEach((item) => {
        if (item.companyId == row.companyId) {
          this.$refs.alltipleTable.toggleRowSelection(item, false);
        }
      });
      // //删除已选择数据
      // this.selectedStoreList = this.selectedStoreList.filter(
      //   (item) => item.companyId !== row.companyId
      // );
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      _api
        .listFault({
          pageNum: val,
          machineTypeId: 1,
          pageSize: 10,
        })
        .then((res) => {
          if (res.code === 1) {
            this.faultTypeList = res.data;
            console.log(this.faultTypeList);
          }
        });
    },
    // 开关切换
    switchChange(v, row, item) {
      this.switchDialogProps = {
        ...this.switchDialogProps,
        show: true,
        tipText: `确认${v ? "开启" : "关闭"}“${item.title}”备注选项`,
        row,
        item,
        value: v,
      };
    },
    // 开关确定
    switchConfig() {
      const { row, item, value } = this.switchDialogProps;
      let switchData = {};
      this.switchData.forEach((v) => {
        switchData[v.key] = row[v.key];
      });
      const data = {
        id: row.id,
        ...switchData,
        [item.key]: value,
      };
      _api.faultSetConfig(data).then((r) => {
        this.$message.success("操作成功");
        this.switchDialogProps = {
          ...this.switchDialogProps,
          show: false,
        };
        this.handleCurrentChange(this.currentPage4);
      });
    },

    handleChange(row) {
      console.log(row.sort, "排序值");
      _api
        .saveupdateSort({
          id: row.id,
          sort: row.sort,
        })
        .then((res) => {
          console.log(res);

          _api
            .listFault({
              pageNum: this.currentPage4,
              machineTypeId: 1,
              pageSize: 10,
            })
            .then((res) => {
              if (res.code === 1) {
                this.faultTypeList = res.data;
                console.log(this.faultTypeList);
              }
            });
        });
    },

    Fn(row) {
      console.log(row, "打印");
    },
    // 类型按钮数组请求
    FaultBtnList() {
      _api
        .listFault({
          pageNum: 1,
          machineTypeId: 1,
          pageSize: 10,
        })
        .then((res) => {
          if (res.code === 1) {
            this.faultTypeList = res.data;
            console.log(this.faultTypeList);
          }
        });
    },
    // 双击编辑按钮
    dblclick(row) {
      console.log(row);
      this.dialogTitle = "编辑选项";
      this.removeFaultAdd = "EditBtn";
      this.FaultId = row.id;
      this.AddTextarea = row.name;
      this.dialogWidth = 600;
      this.dialogVisible = true;
    },
    //添加故障类型按钮
    AddFailure() {
      this.AddTextarea = "";
      this.dialogTitle = "添加选项";
      this.removeFaultAdd = "AddBtn";
      this.dialogWidth = 600;
      this.dialogVisible = true;
    },
    //删除故障类型按钮
    deleteBtn(row) {
      this.dialogTitle = "删除选项";
      this.FaultId = row.id;
      this.dialogWidth = 380;
      this.removeFaultAdd = "deleteBtn";
      this.dialogVisible = true;
    },
    // //弹窗取消
    resetPopupData() {
      this.dialogVisible = false;
    },
    //弹窗确定
    submitPopupData() {
      this.dialogVisible = false;
      //删除
      if (this.removeFaultAdd === "deleteBtn") {
        const id = JSON.parse(this.FaultId);
        _api.removeFault({ id }).then((res) => {
          if (res.code === 1) {
            this.FaultBtnList();
            this.$message({
              message: "删除成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "删除失败",
              type: "error",
            });
          }
        });
      }
      //添加
      else if (this.removeFaultAdd === "AddBtn") {
        const dto = {
          name: this.AddTextarea,
        };
        _api.addFault(dto).then((res) => {
          if (res.code === 1) {
            this.FaultBtnList();
            this.$message({
              message: "添加成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "添加失败",
              type: "error",
            });
          }
        });
      }
      //编辑
      else if (this.removeFaultAdd === "EditBtn") {
        const dto = {
          name: this.AddTextarea,
          id: this.FaultId,
        };
        _api.editFault(dto).then((res) => {
          if (res.code === 1) {
            this.FaultBtnList();
            this.$message({
              message: "编辑成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "编辑失败",
              type: "error",
            });
          }
        });
      }
    },
    onClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: flex-start;
}
.enable-tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;

  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
  }

  .set_top_ck {
    span {
      font-size: 15px;
      font-weight: bold;
      margin-right: 10px;
    }
  }
}
.action_red {
  color: red;
  cursor: pointer;
  text-decoration: underline;
}
.quk_right {
  display: flex;
  align-items: center;
  font-size: 14px;
  .blu_text {
    color: #0981ff;
    cursor: pointer;
    text-decoration: underline;
  }
  .red_text {
    margin-left: 10px;
    color: #ff687b;
    cursor: pointer;
    text-decoration: underline;
  }
}
.failure {
  .case-title {
    display: flex;

    .case-title_min {
      margin-left: 5px;
      font-size: 12px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      color: #ff687b;
      line-height: 45px;
    }
  }

  .faultBtn {
    display: flex;
    flex-wrap: wrap;
    background: #f5f6fa;
    width: 100%;

    .faultBut_item {
      user-select: none;
      align-items: center;
      margin: 10px 25px;
      height: 30px;
      background: #ffffff;
      border: 1px solid #0981ff;
      border-radius: 15px;
      text-align: center;
      line-height: 30px;
      font-size: 14px;
      color: #0981ff;
      padding: 0 5px;
    }

    .faultBut_item:hover {
      height: 30px;
      background: #0981ff;
      font-size: 14px;
      border: 1px solid #0981ff;
      margin: 10px 25px;
      color: white;
      border-radius: 15px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
    }
  }

  .AddDialog {
    .title {
      width: 232px;
      height: 12px;
      font-size: 12px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      color: #ff687b;
      margin-bottom: 20px;
    }
  }
}

/deep/ .el-switch__label * {
  line-height: 1;
  font-size: 12px;
  display: inline-block;
}

/deep/ .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
  font-size: 12px !important;
}

/*打开时文字位置设置*/
/deep/ .el-switch__label--right {
  z-index: 1;
  right: 4px;
}

/*关闭时文字位置设置*/
/deep/ .el-switch__label--left {
  z-index: 1;
  left: 4px;
}

/*显示文字*/
/deep/ .el-switch__label.is-active {
  display: block;
}

/*开关宽度*/
/deep/ .el-switch .el-switch__core,
/deep/ .el-switch .el-switch__label {
  width: 50px !important;
}
</style>
